document.addEventListener( "DOMContentLoaded", () => {
	function setUpMobileMenu() {
		const mobileNavigationMenuSelector = ".mobile-navigation-menu"

		const mobileMenuElement = document.querySelector( mobileNavigationMenuSelector )
		if ( !mobileMenuElement ) {
			return
		}

		const menu = new Mmenu( mobileNavigationMenuSelector, {
			extensions: [
				"position-back",
				"position-top",
			],
			navbars: [],
			screenReader: {
				aria: true,
				text: true,
			},
		});

		$( ".mobile-menu-button" ).click( () => {
			if ( $( mobileNavigationMenuSelector ).hasClass( "mm-menu_opened" ) ) {
				menu.API.close()
			}
			else {
				menu.API.open()
			}
		});
	}

	setUpMobileMenu()


	/* FitVids */
	if ($("body").innerWidth() <= 767) {
		$(".inside-inner-container").fitVids({
			ignore: "nofit"
		});
	}

	/* Accessible menu */
	$(".accesible-navigation-menu").accessibleMenu();

	/* Sticky Header */
	addStickyHeader();
	$(window).scroll(function() {
		addStickyHeader();
	});

	/* Homepage */
	$('.reveal-on-load').addClass('active');

	/* Scroll to top */
	$("a[href='#top']").click(function() {
		$("html, body").animate({ scrollTop: 0 }, "slow");

		return false;
	});
});

function addStickyHeader() {
	if ($(window).scrollTop() > 1) {
		$('.fusion-header-wrapper').addClass('fusion-is-sticky');
	}
	else {
		$('.fusion-header-wrapper').removeClass('fusion-is-sticky');
	}
}

function reveal() {
	var reveals = document.querySelectorAll(".reveal");

	for (var i = 0; i < reveals.length; i++) {
		var windowHeight = window.innerHeight;
		var elementTop = reveals[i].getBoundingClientRect().top;
		var elementVisible = 20;

		if (elementTop < windowHeight - elementVisible) {
			reveals[i].classList.add("active");
		}
	}

	// back to top button
	const backtoTop = document.querySelector("#toTop")
	if ( backtoTop ) {
		if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
			backtoTop.classList.add("active");
		}
		else {
			backtoTop.classList.remove("active");
		}
	}
}

window.addEventListener("scroll", reveal);
